.MuiPaper-elevation4 {
    background-color: rgba(256, 256, 256, 1) !important;
    /* box-shadow: none; */
}
.customNav {
    padding: 0.5rem 0;
    display: flex;
    align-content: center;
}
.navItem {
    font-family: "Roboto" !important;
    font-weight: 450 !important;
    font-size: 1rem !important;
    margin: 1rem 1rem !important;
    color: #231f20;
    display: block;
    text-decoration: none;
}
.navItem:hover{
    color: #767374;
    cursor: pointer;
}
.navItem.active{
    color: #f29328;
    cursor: pointer;
}
.logoNav{
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #231f20;
}
.logoNav:hover{
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #231f20;
}