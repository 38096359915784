.drawerItem:hover {
    background-color: rgba(192, 13, 14, 0.2) !important;
}
.drawerselected {
    background-color: rgba(192, 13, 14, 0.2) !important;
    border-right: solid rgb(192, 13, 14) !important;
}
.fontDrawerItem span {
    font-family: "Roboto" !important;
    font-weight: 400;
    font-size: 1.1rem !important;
}
